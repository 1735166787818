// TODO: Comment out unnecessary files

// Bootstrap variables: we will override with our _variable.scss
@import '../../../../node_modules/bootstrap/scss/variables';

// Mixin
@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    box-shadow: $shadow;
  }
}

@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }
}
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../node_modules/bootstrap/scss/mixins/hover';
@import '../../../../node_modules/bootstrap/scss/mixins/image';
// @import '../../../../node_modules/bootstrap/scss/mixins/badge';
@import '../../../../node_modules/bootstrap/scss/mixins/resize';
// @import '../../../../node_modules/bootstrap/scss/mixins/screen-reader';
@import '../../../../node_modules/bootstrap/scss/mixins/size';
@import '../../../../node_modules/bootstrap/scss/mixins/reset-text';
@import '../../../../node_modules/bootstrap/scss/mixins/text-emphasis';
@import '../../../../node_modules/bootstrap/scss/mixins/text-hide';
@import '../../../../node_modules/bootstrap/scss/mixins/text-truncate';
@import '../../../../node_modules/bootstrap/scss/mixins/transforms';
@import '../../../../node_modules/bootstrap/scss/mixins/visibility';
// @import '../../../../node_modules/bootstrap/scss/mixins/alert';
@import '../../../../node_modules/bootstrap/scss/mixins/buttons';
@import '../../../../node_modules/bootstrap/scss/mixins/cards';
@import '../../../../node_modules/bootstrap/scss/mixins/pagination';
@import '../../../../node_modules/bootstrap/scss/mixins/lists';
// @import '../../../../node_modules/bootstrap/scss/mixins/list-group';
@import '../../../../node_modules/bootstrap/scss/mixins/nav-divider';
@import '../../../../node_modules/bootstrap/scss/mixins/forms';
@import '../../../../node_modules/bootstrap/scss/mixins/table-row';
@import '../../../../node_modules/bootstrap/scss/mixins/background-variant';
@import '../../../../node_modules/bootstrap/scss/mixins/border-radius';
// @import '../../../../node_modules/bootstrap/scss/mixins/gradients';
@import '../../../../node_modules/bootstrap/scss/mixins/clearfix';
// @import '../../../../node_modules/bootstrap/scss/mixins/navbar-align';
@import '../../../../node_modules/bootstrap/scss/mixins/grid-framework';
@import '../../../../node_modules/bootstrap/scss/mixins/grid';
@import '../../../../node_modules/bootstrap/scss/mixins/float';
// @import '../../../../node_modules/bootstrap/scss/custom';

// Reset and dependencies
@import '../../../../node_modules/bootstrap/scss/normalize';
@import '../../../../node_modules/bootstrap/scss/print';

// Core CSS
// @import '../../../../node_modules/bootstrap/scss/reboot';
@import '../../../../node_modules/bootstrap/scss/type';
@import '../../../../node_modules/bootstrap/scss/images';
// @import '../../../../node_modules/bootstrap/scss/code';
@import '../../../../node_modules/bootstrap/scss/grid';
@import '../../../../node_modules/bootstrap/scss/tables';
@import '../../../../node_modules/bootstrap/scss/forms';
@import '../../../../node_modules/bootstrap/scss/buttons';

// Components
@import '../../../../node_modules/bootstrap/scss/transitions';
@import '../../../../node_modules/bootstrap/scss/dropdown';
@import '../../../../node_modules/bootstrap/scss/button-group';
@import '../../../../node_modules/bootstrap/scss/input-group';
@import '../../../../node_modules/bootstrap/scss/custom-forms';
@import '../../../../node_modules/bootstrap/scss/nav';
@import '../../../../node_modules/bootstrap/scss/navbar';
@import '../../../../node_modules/bootstrap/scss/card';
// @import '../../../../node_modules/bootstrap/scss/breadcrumb';
@import '../../../../node_modules/bootstrap/scss/pagination';
// @import '../../../../node_modules/bootstrap/scss/badge';
// @import '../../../../node_modules/bootstrap/scss/jumbotron';
// @import '../../../../node_modules/bootstrap/scss/alert';
// @import '../../../../node_modules/bootstrap/scss/progress';
// @import '../../../../node_modules/bootstrap/scss/media';
// @import '../../../../node_modules/bootstrap/scss/list-group';
// @import '../../../../node_modules/bootstrap/scss/responsive-embed';
@import '../../../../node_modules/bootstrap/scss/close';

// Components w/ JavaScript
@import '../../../../node_modules/bootstrap/scss/modal';
@import '../../../../node_modules/bootstrap/scss/tooltip';
@import '../../../../node_modules/bootstrap/scss/popover';

// Utility classes
@import '../../../../node_modules/bootstrap/scss/utilities/align';
@import '../../../../node_modules/bootstrap/scss/utilities/background';
@import '../../../../node_modules/bootstrap/scss/utilities/borders';
@import '../../../../node_modules/bootstrap/scss/utilities/clearfix';
@import '../../../../node_modules/bootstrap/scss/utilities/display';
@import '../../../../node_modules/bootstrap/scss/utilities/flex';
@import '../../../../node_modules/bootstrap/scss/utilities/float';
@import '../../../../node_modules/bootstrap/scss/utilities/position';
// @import '../../../../node_modules/bootstrap/scss/utilities/screenreaders';
@import '../../../../node_modules/bootstrap/scss/utilities/sizing';
@import '../../../../node_modules/bootstrap/scss/utilities/spacing';
@import '../../../../node_modules/bootstrap/scss/utilities/text';
@import '../../../../node_modules/bootstrap/scss/utilities/visibility';

.btn-link {
  color: var(--color-white);
  &:focus,
  &:hover {
    color: var(--color-white);
    text-decoration: none;
  }
}

