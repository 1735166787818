@import 'theme/theme-odyssey';
@import 'theme/theme-rea';
@import 'theme/theme-ecreee';
@import 'theme/theme-anper';
@import 'theme/theme-db';
@import 'theme/theme-aser';
@import 'theme/theme-nerc';
@import 'theme/theme-essor';
@import 'theme/theme-cross-boundary';
@import 'theme/theme-eeu';
@import 'theme/theme-somaliland';
@import 'theme/theme-nea';
@import 'theme/theme-amader';
@import 'theme/theme-esmap-hfe';
@import 'theme/theme-ipff';
@import 'theme/theme-sun-africa';
@import 'theme/theme-uef';
@import 'theme/theme-rensource';
@import 'theme/theme-ifc';
@import 'theme/theme-gbe-benin';
@import 'theme/theme-gbe-cotedivoire';
@import 'theme/theme-benin';
@import 'theme/theme-phares';
@import 'theme/theme-dream-ethiopia';
@import 'theme/theme-brilho';
@import 'theme/theme-epp';
@import 'theme/theme-earf';
@import 'theme/theme-paop';
@import 'theme/theme-aber';
@import 'theme/theme-arene';
@import 'theme/theme-tchad';
@import 'theme/theme-funae';
@import 'theme/theme-ogef';
@import 'theme/theme-ceiafrica';
@import 'theme/theme-powertrust';
@import 'theme/theme-rrealiberia';
@import 'theme/theme-avorenewables';
@import 'theme/theme-omdf';
@import 'theme/theme-a2ei';
@import 'theme/theme-amap';
@import 'theme/theme-ager';
@import 'theme/theme-rmi';
@import 'theme/theme-burundi';
@import 'theme/theme-cpi';
@import 'theme/theme-proenergia';
@import 'theme/theme-amadermali';
@import 'theme/theme-tdbgroup';
@import 'theme/theme-fondsdecim';
@import 'theme/theme-dares';

// These colors apply to all themes
@mixin theme-global {
  --color-baseline-900: #101828;
  --color-baseline-800: #1D2939;
  --color-baseline-700: #344054;
  --color-baseline-600: #475467;
  --color-baseline-500: #667085;
  --color-baseline-400: #98A2B3;
  --color-baseline-300: #D0D5DD;
  --color-baseline-200: #EAECF0;
  --color-baseline-100: #F2F4F7;
  --color-baseline-50: #F9FAFB;
  --color-baseline-25: #FCFCFD;

  --color-success-900: #2B4212;
  --color-success-800: #335015;
  --color-success-700: #3F621A;
  --color-success-600: #4F7A21;
  --color-success-500: #669F2A;
  --color-success-400: #86CB3C;
  --color-success-300: #ACDC79;
  --color-success-200: #CEEAB0;
  --color-success-100: #E6F4D7;
  --color-success-50: #F5FBEE;
  --color-success-25: #FAFDF7;

  --color-error-900: #7A271A;
  --color-error-800: #8C2A1C;
  --color-error-700: #912018;
  --color-error-600: #D92D20;
  --color-error-500: #F04438;
  --color-error-400: #F97066;
  --color-error-300: #FDA29B;
  --color-error-200: #FECDCA;
  --color-error-100: #FEE4E2;
  --color-error-50: #FEF3F2;
  --color-error-25: #FFFBFA;

  --color-warning-900: #7A2E0E;
  --color-warning-800: #93370D;
  --color-warning-700: #B54708;
  --color-warning-600: #DC6803;
  --color-warning-500: #F79009;
  --color-warning-400: #FDB022;
  --color-warning-300: #FEC84B;
  --color-warning-200: #FEDF89;
  --color-warning-100: #FEF0C7;
  --color-warning-50: #FFFAEB;
  --color-warning-25: #FFFCF5;

  --color-accent-a-900: #713B12;
  --color-accent-a-800: #854A0E;
  --color-accent-a-700: #A15C07;
  --color-accent-a-600: #CA8504;
  --color-accent-a-500: #EAAA08;
  --color-accent-a-400: #FAC515;
  --color-accent-a-300: #FDE272;
  --color-accent-a-200: #FEEE95;
  --color-accent-a-100: #FEF7C3;
  --color-accent-a-50: #FEFBE8;
  --color-accent-a-25: #FEFDF0;

  --color-accent-b-900: #2B5314;
  --color-accent-b-800: #326212;
  --color-accent-b-700: #3B7C0F;
  --color-accent-b-600: #4CA30D;
  --color-accent-b-500: #66C61C;
  --color-accent-b-400: #85E13A;
  --color-accent-b-300: #A6EF67;
  --color-accent-b-200: #D0F8AB;
  --color-accent-b-100: #E3FBCC;
  --color-accent-b-50: #F3FEE7;
  --color-accent-b-25: #FAFEF5;

  --color-accent-c-900: #491C96;
  --color-accent-c-800: #5720B7;
  --color-accent-c-700: #6927DA;
  --color-accent-c-600: #7839EE;
  --color-accent-c-500: #875BF7;
  --color-accent-c-400: #A48AFB;
  --color-accent-c-300: #C3B5FD;
  --color-accent-c-200: #DDD6FE;
  --color-accent-c-100: #ECE9FE;
  --color-accent-c-50: #F5F3FF;
  --color-accent-c-25: #FBFAFF;

  --color-white: #FFFFFF;
  --color-white-opacity-80: rgba(255, 255, 255, 0.8);
  --color-white-opacity-60: rgba(255, 255, 255, 0.6);
  --color-white-opacity-40: rgba(255, 255, 255, 0.4);
  --color-white-opacity-20: rgba(255, 255, 255, 0.2);
  --color-white-opacity-15: rgba(255, 255, 255, 0.15);
  --color-white-opacity-10: rgba(255, 255, 255, 0.1);

  --color-black: #000000;
  --color-black-opacity-88: rgba(0, 0, 0, 0.88);
  --color-black-opacity-62: rgba(0, 0, 0, 0.62);
  --color-black-opacity-50: rgba(0, 0, 0, 0.50);
  --color-black-opacity-25: rgba(0, 0, 0, 0.25);
  --color-black-opacity-15: rgba(0, 0, 0, 0.15);
  --color-black-opacity-10: rgba(0, 0, 0, 0.10);

  // Extra colors in use
  --color-font-in-progress: #026AA2;
  --color-light-blue-100: #E0F2FE;

  // Old color names (deprecated)
  --color-font-primary: var(--color-baseline-900);
  --color-font-secondary: var(--color-baseline-700);
  --color-font-tertiary: var(--color-baseline-500);

  --color-font-success: var(--color-success-700);

  --color-cool-gray: #EFF1F5;
  --color-cool-gray-300: #B9C0D4;
  --color-blue-gray: #EAECF5;
  // The 3 grays above matched to closest colors within baseline palette
  // --color-cool-gray: var(--color-baseline-100);
  // --color-cool-gray-300: var(--color-baseline-400);
  // --color-blue-gray: var(--color-baseline-200);

  --color-gray-300: var(--color-baseline-300);
  --color-gray-200: var(--color-baseline-200);
  --color-light-gray: var(--color-baseline-100);
  --color-gray-50: var(--color-baseline-50);

  --color-orange: #FF7F00;
  --color-dark-orange: #E67200;
  --color-light-orange: #FFE5CC;
  --color-orange-faded: #FFE7CF;
  --color-orange-faded-darkened: #FED7BE;
  // Oranges above matched to closest new colors
  // --color-orange: var(--color-warning-500);
  // --color-dark-orange: var(--color-warning-600);
  // --color-light-orange: var(--color-warning-100);
  // --color-orange-faded: var(--color-warning-100);
  // --color-orange-faded-darkened: var(--color-warning-200);

  --color-yellow: #F5CE3C;
  --color-light-yellow: #EFE1C7;
  --color-soft-yellow: #FEF7C3;
  --color-dark-yellow: #A15C07;
  // Yellows above matched to closest new colors
  // --color-yellow: var(--color-accent-a-400);
  // --color-light-yellow: var(--color-warning-100);
  // --color-soft-yellow: var(--color-accent-a-100);
  // --color-dark-yellow: var(--color-accent-a-700);

  --color-orange-yellow: #F7B12D;
  --color-light-orange-yellow: #FEECD0;
  --color-dark-orange-yellow: #d2a834;
  --color-hay-orange: #F3EBE0;
  // Four oranges above matched to closest new colors
  // --color-orange-yellow: var(--color-warning-400);
  // --color-light-orange-yellow: var(--color-warning-100);
  // --color-dark-orange-yellow: var(--color-accent-a-500);
  // --color-hay-orange: var(--color-warning-50);

  --color-green: #0DBC8C;
  --color-dark-green: #0EA87E;
  --color-light-green: #C1E4DA;
  --color-soft-green: #E3FBCC;
  --color-msexcel-green: #00622E;
  --color-dry-green: #E8EFE0;
  --color-moss: #E6F4D7;
  // Greens above matched to closest new colors
  // --color-green: var(--color-accent-b-500);
  // --color-dark-green: var(--color-accent-b-600);
  // --color-light-green: var(--color-success-200);
  // --color-soft-green: var(--color-accent-b-100);
  // --color-msexcel-green: var(--color-accent-b-800);
  // --color-dry-green: var(--color-success-100);
  // --color-moss: var(--color-success-100);

  --color-blue: #30819F;
  --color-blue-200: #D3EDF4;
  --color-light-blue: #C8D8DE;
  --color-bright-blue: blue;
  --color-msword-blue: #0275D8;
  --color-dark-blue: #256880;
  --color-link-blue: #0064bd;
  --color-soft-blue: #E0F2FE;
  --color-ocean-blue: #10AEDC;
  --color-dark-ocean-blue: #1CA5CD;
  --color-dark-ocean-blue-opacity-8: rgba(24, 165, 205, 0.08);

  --color-red: #CC2A16;
  --color-red-darken-5: #B52514;
  --color-light-red: #E7C6C2;
  --color-light-red-lighten-2: #F5D4D0;
  --color-light-red-lighten-5: #FFEFED;
  --color-dull-red: #A94442;
  // The reds above matched to closest new colors
  // --color-red: var(--color-error-600);
  // --color-red-darken-5: var(--color-error-700);
  // --color-light-red: var(--color-error-200);
  // --color-light-red-lighten-2: var(--color-error-200);
  // --color-light-red-lighten-5: var(--color-error-50);
  // --color-dull-red: var(--color-error-800);

  --color-purple: purple;
  --color-lavender-purple: #EBE9FE;
  // The purples matched to closest new colors
  // --color-purple: var(--color-accent-c-700);
  // --color-lavender-purple: var(--color-accent-c-100);
}
