@import 'variables'; // oes variables to override bootstrap
@import 'override/bootstrap'; // Partially loading bootstrap scss files.

// ag-grid
@import 'override/ag_grid';

// Form.io
@import 'override/formio';
// ngx-bootstrap date/daterange picker
@import 'override/datepicker';
// arch wizard
@import 'override/archwizard';
// multi select dropdown library
@import 'override/ngx-bootstrap-multiselect';

// OES Style
@import 'buttons';
@import 'fixed_layout';
@import 'forms';
@import 'icons';
@import 'input_controls';
@import 'media_print';
@import 'modal';
@import 'no_data';
@import 'tab_collapse';
@import 'typography';
@import 'theme';  // mixin
@import 'tab';

html {
  height: 100%;
}

//fixed issues here within .map-bg class
body {
  font-family: Inter;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: fit-content;
  min-height: 100%;
  color: var(--color-black-grey);
}

// Tailwind preflight override
img,
video {
  max-width: revert-layer;
  height: revert-layer;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: initial;
}

// ---

// override bootstrap.css
button, input, optgroup, select, textarea {
  font-family: Inter;
}

h1,h2,h3 {
  font-family: Plus Jakarta Sans;
}

a {
  color: var(--color-brand-a-700);
  text-decoration: underline;
}

.preheader {
  color: var(--color-brand);
  font-weight: 600;
  margin-bottom: 12px;
}

.opacity-50 {
  opacity: .5;
}

.fancy-button {
  line-height: 0;
  padding: 0 15px 0 0;
  border: none !important;
  display: flex;
  align-items: center;

  img {
    margin-right: 11px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 7px 10px 7px 15px;
    height: 44px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: none;
  }
}

button, select, input, textarea {
  line-height: inherit;
}

.home p {
  margin-bottom: 1rem;
}

.oes-tab {
  a {
    color: var(--color-black);
    &:hover {
      color: var(--color-black);
    }
  }
}

.sebm-google-map-container {
  height: 200px;
}

.form-control{
  border-radius: 0;
}

.rounded {
  border-radius: 8px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.chart-legend {
  .legend-labels {
    width: initial !important;
    max-width: 180px;
    white-space: nowrap;
    position: absolute;
    top: 60px;
    right: 10px
  }
}

.bg-white {
  background-color: var(--color-white);

  // full page width, no gutters
  &--fw {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.bg-light-grey {
  background-color: var(--color-light-grey);
}

.primary {
  color: var(--color-brand);
}

nav li a,
a.btn,
button a,
.nav-link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.tabset,
tabset {
  .nav-tabs {
    border-bottom: 1px solid var(--color-grey-70);

    .nav-item:nth-of-type(1) .nav-link {
      padding-left: 0;
    }

    .nav-item {
      color: var(--color-black-grey);
      font-size: 14px;

      &.disabled {
        color: var(--color-med-grey);
      }

      &:focus,
      &:hover {
        border-color: transparent;
        outline: none;
      }

      span {
        &:hover {
          cursor: pointer;
        }
      }

      .nav-link {
        height: 40px;
        color: var(--color-font-secondary);
        line-height: 20px;

        &.active {
          color: var(--color-font-secondary);
        }

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  &.plain-tabs > .nav-tabs > .nav-item > .nav-link {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    margin-right: 24px;
    border-bottom: 2px solid transparent !important;
    color: var(--color-font-tertiary);

    &.active {
      text-shadow: .1px .1px 0 var(--color-font-secondary),
                -.1px -.1px 0 var(--color-font-secondary),
                 .1px -.1px 0 var(--color-font-secondary),
                -.1px .1px 0 var(--color-font-secondary);
      color: var(--color-font-secondary);
      border-bottom-color: var(--color-brand) !important;
    }

    &:hover {
      color: var(--color-font-secondary);
      border-bottom-color: var(--color-brand) !important;
    }
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    background-color: transparent;
    color: var(--color-brand);
    border: none;
    text-shadow: .15px .15px 0 var(--color-brand),
                -.15px -.15px 0 var(--color-brand),
                 .15px -.15px 0 var(--color-brand),
                -.15px .15px 0 var(--color-brand);

    &:focus,
    &:hover {
      border: none;
    }

    span {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: -11px;
        width: 100%;
        // height: 4px;
        display: block;
        background-color: var(--color-brand);
        left: 0;
      }
    }
  }

  .tab-content {
    padding-top: 22px;
  }

  &.button-tabs {
    .nav-tabs {
      padding-bottom: 10px;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      font-family: Inter;

      span {
        position: unset;

        &:after {
          position: unset;
          bottom: unset;
          width: unset;
          height: unset;
          display: unset;
          background-color: none;
        }
      }
    }

    .nav-item {
      font-size: 14px;
      color: var(--color-grey-15);
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0;
      border-radius: 6px;
      margin-right: 10px;

      a {
        padding: 0;
        padding: 0 14px !important;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        line-height: 1.2;
      }

      &:focus,
      &.active,
      &:hover {
        background: var(--color-brand-opacity-10);

        &.disabled {
          background: transparent;
          cursor: not-allowed;

          a {
            color: var(--color-grey-50);
            cursor: not-allowed !important;
            text-shadow: none;

            span {
              cursor: not-allowed;
            }
          }
        }

        a {
          color: var(--color-brand) !important;
          text-shadow: .1px .1px 0 var(--color-brand),
                      -.1px -.1px 0 var(--color-brand),
                      .1px -.1px 0 var(--color-brand),
                      -.1px .1px 0 var(--color-brand);
        }
      }
    }
  }

  &.underline-tabs {
    .nav-tabs {
      padding-bottom: 0;
    }

    .nav-item {
      color: var(--color-grey-15);
      margin-right: 15px;
      border-bottom: 2px solid transparent;
      border-radius: 0;

      a {
        padding: 0 0 20px !important;
      }

      &:focus,
      &.active,
      &:hover {
        background: transparent;
        border-bottom: 2px solid var(--color-brand);

        a {
          color: var(--color-grey-15);
          text-shadow: .15px .15px 0 var(--color-grey-15),
               -.15px -.15px 0 var(--color-grey-15),
               .15px -.15px 0 var(--color-grey-15),
               -.15px .15px 0 var(--color-grey-15)
        }
      }
    }
  }
}

oes-project-financial-model,
oes-project-specs {
  .handsontable thead {
    font-size: 12px;
    font-family: Inter;
    font-weight: 600;
}

  .handsontable th {
    background-color: var(--color-med-grey);
    border-left: 0;
    border-right: 0;
  }

  .handsontable th:nth-of-type(1) {
    border-right: 0;
  }

  .handsontable thead th .relative {
    padding: 3px 4px 2px;
  }

  .handsontable thead th:nth-of-type(1) .relative {
    text-align: left;
    padding-left: 10px;
    background-color: var(--color-med-grey);
  }

  .handsontable tbody tr td {
    text-align: center !important;
    font-size: 12px;
    &.htLeft {
      text-align: left !important;
      color: var(--color-black-grey) !important;
    }
  }

  .handsontable .ht_clone_left td {
    text-align: left !important;
    color: var(--color-black-grey) !important;
    padding-left: 10px;
  }

  .customer-uptake-table {
    .handsontable tbody tr:nth-last-of-type(1) td {
      font-family: Inter;
      font-weight: 600;
    }
  }
}

.monthPicker {
  flex-grow: 1;
  margin: 0 14px 0 7px;

  .dropdown {
    display: block !important;
    width: 100%;
  }

  .dropdown-toggle {

    &::placeholder {
      color: var(--color-med-grey);
    }
    &:after {
      position: absolute;
      right: 10px;
      top: 13px;
    }
  }

  .btn {
    border-radius: 0;
    width: 100%;
    text-align: left;
    border-color: --color-black-opacity-15 !important;
    height: 30px;
    line-height: 10px;
  }

  .dropdown-menu {
    width: 100%;
    border-radius: 0;
    margin: 0;
    border-top: 0;
    top: 29px;
  }

  .dropdown-item {
    a {
      color: var(--color-black-grey);
      font-size: 12px;
    }
  }
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.display-none {
  display: none !important;
}

input[type="number"] {
  text-align: right;

  &::placeholder {
    text-align: left;
  }
}

.tooltip {
  z-index: 20000;
}

.tool-tip {
  .tooltip-inner {
    background-color: var(--color-dark-grey);
    font-family: Inter;
    font-size: 12px;
    text-align: left;
    white-space: normal;

    &::before {
      border-right-color: var(--color-dark-grey);
    }
  }
}
.tooltip.show {
  opacity: 1;
}

.panel-heading.card-header {
  font-size: 16px;
}

oes-summary-costs {
  .custom-panel {
    border: none;
    margin-bottom: 24px;

    .panel-heading.card-header {
      background-color: var(--color-grey-96);
      border: 1px solid var(--color-grey-80);
      border-radius: 6px 6px 6px 6px;
      padding: 15px 10px 15px 19px;
    }
  }

  .panel-open {
    .custom-panel {
      .panel-heading.card-header {
        border-bottom: none;
        border-radius: 6px 6px 0 0;
      }
    }
  }

  .last-panel {
    .custom-panel {
      margin-bottom: 0 !important;
    }
  }

  .panel-body {
    padding: 0;
    border: 1px solid var(--color-grey-80);
    border-top: 0;
    border-radius: 0 0 6px 6px;

    .table {
      margin-bottom: 0;
    }

    thead {
      background-color: var(--color-grey-96);

      tr {
        height: 36px;
      }

      h5 {
        font-size: 12px;
        color: var(--color-grey-25);
        font-weight: 500;
      }
    }

    tr {
      height: 72px;

      p {
        font-weight: 500;
      }
    }
  }
}

#PureChatWidget {
  max-width: 300px;
}

.err-msg > p {
  font-size: 10px;
  font-weight: bold;
  color: var(--color-red);
}

.inline-message {
  min-height: 30px;
  width: 100%;
  box-shadow: 0 0 18px 0 rgba(0,0,0,0.25);
  background-color: var(--color-white);
  margin-bottom: 16px;

  .icon-box {
    flex: 0 0 30px;
    img {
      margin: 8px;
      height: 14px;
    }
  }

  .close {
    position: relative;
    top: -5px;
    right: 5px;
  }

  .fa {
    font-size: .75rem;
  }

  &.error {
    border: 1px solid var(--color-red);
    .icon-box {
      background-color: var(--color-red);
    }
    p {
      color: var(--color-red);
    }
  }
  &.warning {
    border: 1px solid var(--color-orange-yellow);
    .icon-box {
      background-color: var(--color-orange-yellow);
    }
  }
  &.success {
    border: 1px solid var(--color-green);
    .icon-box {
      background-color: var(--color-green);
    }
    p {
      color: var(--color-green);
    }
  }
}

a.dropdown-item {
  color: var(--color-black) !important;
  text-decoration: none !important;
  padding: 3px 1rem !important;
  font-size: 14px;
  line-height: 1.5;
  input[type="checkbox"] {
   height: 12px;
  }

  .fa-check:before {
    margin-right: 3px;
    position: relative;
    right: 3px;
  }

  &:active {
    background-color: var(--color-light-grey-lighten-3);
  }

  &:hover {
    background-color: var(--color-light-grey-lighten-3);
  }
}

.hint-content {
  padding: 3px 7px 3px;

  a {
    color: var(--color-link-blue) !important;
    text-decoration: underline !important;
  }
}

.brand-link {
  color: var(--color-brand);
}

.brand-link:hover {
  color: var(--color-brand-darken-5);
}

.orange-link {
  font-weight: 500;
  color: var(--color-orange);
}

.orange-link:hover {
  color: var(--color-dark-orange);
}

.link-plain {
  text-decoration: none;
}

.link-plain:hover {
  text-decoration: none;
}

.select-w-placeholder {
  color: var(--color-med-grey);

  option {
    color: var(--color-dark-grey);
  }
}

.segment:after {
  font-family: 'FontAwesome';
  content: '\f111';
  font-size: 10px;
  font-size: 4px;
  padding: 0 4px 0 2px;
  position: relative;
  top: -3px;
}

.loading-text {
  color: rgba(0, 0, 0, 1);
  &:hover {
    color: rgba(0, 0, 0, 1);
  }
}

.loading-dots:after {
  content: '.';
  color: rgba(0, 0, 0, 1);
  animation: loading-dots 2s steps(1, end) infinite;
  margin: 0 10px 0 2px;
}

@keyframes loading-dots {
  0%, 20% {
    color: rgba(255, 255, 255, 0);
    text-shadow: .5em 0 0 rgba(255, 255, 255, 0), 1em 0 0 rgba(255, 255, 255, 0);
  }
  40% {
    color: rgba(255, 255, 255, 1);
    text-shadow: .5em 0 0 rgba(255, 255, 255, 0);
  }
  60% {
    text-shadow: .5em 0 0 rgba(255, 255, 255, 1), 1em 0 0 rgba(255, 255, 255,0);
  }
  80%, 100% {
    text-shadow: .5em 0 0 rgba(255, 255, 255, 1), 1em 0 0 rgba(255, 255, 255,1);
  }
}

// Fresh chat icon
#fc_frame.fc-widget-normal {
  bottom: 0 !important;
  right: 0 !important;
  height: 72px;
}

.loading-dots-black:after {
  content: '.';
  animation: loading-dots-black 2s steps(1, end) infinite;
  margin: 0 10px 0 2px;
}

@keyframes loading-dots-black {
  0% {
    color: var(--color-black);
    text-shadow: .5em 0 0 rgba(0,0,0,0), 1em 0 0 rgba(0,0,0,0);
  }
  30% {
    text-shadow: .5em 0 0 var(--color-black), 1em 0 0 rgba(0,0,0,0);
  }
  60%, 100% {
    text-shadow: .5em 0 0 var(--color-black), 1em 0 0 var(--color-black);
  }
}

.loading-dots-grey:after {
  content: '.';
  animation: loading-dots-grey 2s steps(1, end) infinite;
  margin: 0 10px 0 2px;
}

@keyframes loading-dots-grey {
  0% {
    color: var(--color-grey-35);
    text-shadow: .5em 0 0 rgba(151, 153, 153, 0), 1em 0 0 rgba(151, 153, 153, 0);
  }
  30% {
    text-shadow: .5em 0 0 rgba(151, 153, 153, 1), 1em 0 0 rgba(151, 153, 153, 0);
  }
  60%, 100% {
    text-shadow: .5em 0 0 rgba(151, 153, 153, 1), 1em 0 0 rgba(151, 153, 153, 1);
  }
}

// make a space for all themes, override from each theme component
.home {
  height: 100%;
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-display {
      border-top: 4px solid var(--color-brand);
      border-bottom: 4px solid var(--color-brand);
      img {
        width: 33.333333333%;
      }
    }
    .image-display-with-spacer {
      width: 100%;

      div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          border-left: 4px solid var(--color-brand);
          max-width: 500px;
          width: 33.333333333%;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          display: inline-block;
          aspect-ratio: 5/3;
        }
        :first-child {
          border-left: none;
        }
      }

    }
    .bg-container {
      padding: 2.5rem 15px 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .logos {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .help-banner {
      color: var(--color-light-grey);
      background-color: var(--color-grey-90);
      color: var(--color-dark-grey);
      padding: 20px 20px 17px;
      width: calc(100%);
      margin: 0 auto;
      position: absolute;
      bottom: 0px;
      right: 0px;
      text-align: center;
      h4 {
        margin: 0 auto 10px;
      }
      p {
        margin: 0;
      }
    }
  }
}

.empty-outer {
  display: flex;
  justify-content: center;
  padding: 40px 0 0;
  width: 100%;
  height: 500px;
  .empty {
    width: 100%;
    height: 50%;
    background-color: var(--color-light-grey-lighten-4);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15%;
  }
}

.hyperlink {
  color: var(--color-link-blue) !important;
}

.home-3-image-container {
  display: flex;
  border-top: 4px solid var(--color-brand);
  border-bottom: 4px solid var(--color-brand);
  width: 100%;
  max-width: 1500px;

  .home-image-1,
  .home-image-2,
  .home-image-3 {
    width: 33.33333333333%;
    max-height: 330px;
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 3/2;
  }
}

.button-plus {
  font-size: 29px;
  margin: 0px 15px 0px 4px;
  line-height: 0;
  font-weight: 400;
}

.button-edit {
  width: 30px;
  height: 30px;
  background-image: url('/assets/icons/line-icons/shared/orange/edit.svg');
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  margin: 0px 4px;
}

.summary-box--empty {
  padding: 20px;
  width: 100%;

  .icon-circle {
    background: var(--color-grey-85);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-grey-20);
    margin: 10px 0 3px;
  }

  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &.costs-section {
    border-top: 1px solid var(--color-grey-80);
    padding: 24px 20px 30px;
  }
}

.section-border {
  border: 1px solid var(--color-grey-80);
  border-radius: 8px;
  padding: 24px;
}

.icon-round-bg {
  background-color: var(--color-grey-80);
  border-radius: 50%;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-buttons {
  display: flex;
  border-bottom: 1px solid var(--color-grey-70);
  padding: 0 0 10px 0;
  margin-bottom: 30px;

  li {
    margin-right: 10px;
    list-style-type: none;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--color-grey-25);
      padding: 10px 16px;
      display: flex;
    }

    &:hover,
    &.active {
      background-color: var(--color-brand-opacity-10);

      a {
        color: var(--color-brand);
      }
    }
  }

  &.tabs {
    tabset {
      width: 100%;

      & > ul {
        padding-bottom: 10px;

        li {
          height: 40px;
          padding: 0;

          a {
            padding: 10px 16px !important;
          }
        }
      }
    }
  }
}

.print-modal-open {
  height: 100%;
  overflow: visible;
}

.go-back {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 10px;
  padding-top: 15px;

  .icon-container {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-grey-85);
    border-radius: 25px;
    margin-right: 9px;
  }
}

.arrow-down-icon {
  margin-left: 12px;
  margin-bottom: 2px;
}

.top-page-heading {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 24px;

  h3 {
    line-height: 54px;
    margin: 0;
  }
}

.line-break {
  width: 100%;
  height: 1px;
  background: var(--color-grey-80);
  margin: 24px 0;
}

.program-owner-excel {
  margin-right: 197px;
  position: relative;
  bottom: 3px;
}

.loading-ring {
  color: var(--color-brand);
}
.loading-ring,
.loading-ring div {
  box-sizing: border-box;
}
.loading-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.loading-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 38px;
  height: 38px;
  margin: 8px;
  border: 4px solid currentColor;
  border-radius: 50%;
  animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;

}
.loading-ring-small {
  padding: 5px;
}
.loading-ring-small div {
  width: 22px !important;
  height: 22px !important;
  border-width: 3px !important;
}
.loading-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media print {
  html.print-modal-open, body.print-modal-open {
    height: 100%;
    overflow: visible;
    visibility: hidden;
  }
}

:root {
  .odyssey-theme {
    @include theme-global;
    @include theme-odyssey;
  }

  .ecreee-theme {
    @include theme-global;
    @include theme-ecreee;
  }

  .rea-theme {
    @include theme-global;
    @include theme-rea;
  }

  .rearef-theme {
    @include theme-global;
    @include theme-rea;
  }

  .reaeei-theme {
    @include theme-global;
    @include theme-rea;
  }

  .anper-theme {
    @include theme-global;
    @include theme-anper;
  }

  .db-theme {
    @include theme-global;
    @include theme-db;
  }

  .aser-theme {
    @include theme-global;
    @include theme-aser;
  }

  .nerc-theme {
    @include theme-global;
    @include theme-nerc;
  }

  .essor-theme {
    @include theme-global;
    @include theme-essor;
  }

  .cb-theme {
    @include theme-global;
    @include theme-cb;
  }

  .eeu-theme {
    @include theme-global;
    @include theme-eeu;
  }

  .somaliland-theme {
    @include theme-global;
    @include theme-somaliland;
  }

  .nea-theme {
    @include theme-global;
    @include theme-nea;
  }

  .amader-theme {
    @include theme-global;
    @include theme-amader;
  }

  .esmap-hfe-theme {
    @include theme-global;
    @include theme-esmap-hfe;
  }

  .ipff-theme {
    @include theme-global;
    @include theme-ipff;
  }

  .cpi-theme {
    @include theme-global;
    @include theme-cpi;
  }

  .proenergia-theme {
    @include theme-global;
    @include theme-proenergia;
  }

  .amadermali-theme {
    @include theme-global;
    @include theme-amadermali;
  }

  .tdbgroup-theme {
    @include theme-global;
    @include theme-tdbgroup;
  }

  .fondsdecim-theme {
    @include theme-global;
    @include theme-fondsdecim;
  }

  .dares-theme {
    @include theme-global;
    @include theme-dares;
  }

  .burundi-theme {
    @include theme-global;
    @include theme-burundi;
  }

  .amap-theme {
    @include theme-global;
    @include theme-amap;
  }

  .ager-theme {
    @include theme-global;
    @include theme-ager;
  }

  .rmi-theme {
    @include theme-global;
    @include theme-rmi;
  }

  .sun-africa-theme {
    @include theme-global;
    @include theme-sun-africa;
  }

  .uef-theme {
    @include theme-global;
    @include theme-uef;
  }

  .rensource-theme {
    @include theme-global;
    @include theme-rensource;
  }

  .ifc-theme {
    @include theme-global;
    @include theme-ifc;
  }

  .gbe-benin-theme {
    @include theme-global;
    @include theme-gbe-benin;
  }

  .gbe-cotedivoire-theme {
    @include theme-global;
    @include theme-gbe-cotedivoire;
  }

  .benin-theme {
    @include theme-global;
    @include theme-benin;
  }

  .phares-theme {
    @include theme-global;
    @include theme-phares;
  }

  .dream-ethiopia-theme {
    @include theme-global;
    @include theme-dream-ethiopia;
  }

  .brilho-theme {
    @include theme-global;
    @include theme-brilho;
  }

  .epp-theme {
    @include theme-global;
    @include theme-epp;
  }

  .earf-theme {
    @include theme-global;
    @include theme-earf;
  }

  .paop-theme {
    @include theme-global;
    @include theme-paop;
  }

  .aber-theme {
    @include theme-global;
    @include theme-aber;
  }

  .arene-theme {
    @include theme-global;
    @include theme-arene;
  }

  .tchad-theme {
    @include theme-global;
    @include theme-tchad;
  }

  .funae-theme {
    @include theme-global;
    @include theme-funae;
  }

  .ogef-theme {
    @include theme-global;
    @include theme-ogef;
  }

  .ceiafrica-theme {
    @include theme-global;
    @include theme-ceiafrica;
  }

  .powertrust-theme {
    @include theme-global;
    @include theme-powertrust;
  }

  .rrealiberia-theme {
    @include theme-global;
    @include theme-rrealiberia;
  }

  .avorenewables-theme {
    @include theme-global;
    @include theme-avorenewables;
  }

  .omdf-theme {
    @include theme-global;
    @include theme-omdf;
  }

  .a2ei-theme {
    @include theme-global;
    @include theme-a2ei;
  }
}
